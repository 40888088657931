/* Feedback form container styles */
.feedback-form-container {
    max-width: 28rem; /* max-w-md */
    margin: 2rem auto; /* mx-auto mt-8 */
    padding: 1.5rem; /* p-6 */
    background-color: white;
    border-radius: 0.5rem; /* rounded-lg */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* shadow */
  }
  
  .feedback-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem; /* mb-4 */
  }
  
  .feedback-title {
    font-size: 1.5rem; /* text-2xl */
    font-weight: bold; /* font-bold */
  }
  
  .logout-button {
    color: #f56565; /* text-red-500 */
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .logout-button:hover {
    color: #e53e3e; /* hover:text-red-600 */
  }
  
  .feedback-form {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* space-y-4 */
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.25rem; /* mb-1 */
  }
  
  .form-textarea {
    width: 100%;
    padding: 0.5rem; /* p-2 */
    border: 1px solid #d1d5db; /* border */
    border-radius: 0.25rem; /* rounded */
    height: 8rem; /* h-32 */
  }
  
  .submit-button {
    width: 100%;
    background-color: #4299e1; /* bg-blue-500 */
    color: white;
    padding: 0.5rem; /* p-2 */
    border-radius: 0.25rem; /* rounded */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #3182ce; /* hover:bg-blue-600 */
  }
  
  .submit-button.disabled {
    background-color: #63b3ed; /* disabled:bg-blue-300 */
    cursor: not-allowed;
  }
  