

  /* src/components/DesignPad/styles/DesignPad.css */
  .design-pad {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #f0f2f5;
    overflow: hidden;
  }
  
  .design-pad__logo-section {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
    width: 75px;
    height: 75px;
  }
  
  .design-pad__stories-section {
    position: relative;
   /* Below logo section */
    left: 60px;
    right: 0;
    padding: 20px;
    z-index: 900;
    overflow-x: auto;
    display: flex;
    gap: 20px;
    margin-bottom: 1rem;
  }
  
  .design-pad__documents {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 900;
  }
  
  .design-pad__canvas-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  #canvas {
    width: 100%;
    height: 100%;
  }
  

