/* About container styles */
.about-container {
    max-width: 1000px; /* container */
    margin: 2rem auto; /* mx-auto py-8 */
    padding: 1rem; /* px-4 */
  }
  
  /* Title styles */
  .about-title {
    font-size: 2rem; /* text-3xl */
    font-weight: bold; /* font-bold */
    margin-bottom: 1.5rem; /* mb-6 */
  }
  
  /* Content area styles */
  .about-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #4a5568; /* text-gray-700 */
  }
  
  /* Text paragraph styles */
  .about-text {
    margin-bottom: 1rem; /* mb-4 */
  }
  
  /* Subtitle styles */
  .about-subtitle {
    font-size: 1.5rem; /* text-2xl */
    font-weight: bold; /* font-bold */
    margin-top: 2rem; /* mt-8 */
    margin-bottom: 1rem; /* mb-4 */
  }
  
  /* List styles */
  .features-list {
    list-style-type: disc; /* list-disc */
    padding-left: 1.5rem; /* pl-6 */
    margin-bottom: 1rem; /* mb-4 */
  }
  