@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

#sign {
  color: #333;
}

span {
  font-size: 12px;
  color: #333;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.sign-btn {
  border-radius: 20px;
  border: 1px solid #097dea;
  background: #097dea;
  background: linear-gradient(65deg, #0270d7 0, #0f8afd 100%);
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
  margin: 10px 0;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.sign-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 380px;
  max-width: 100%;
  min-height: 550px;
  margin: 50px auto;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 100%;
  z-index: 2;
}

.sign-up-container {
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 1;
}

.sign-container.right-panel-active .sign-in-container {
  opacity: 0;
  z-index: 1;
}

.sign-container.right-panel-active .sign-up-container {
  opacity: 1;
  z-index: 2;
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
  color: #333;
  text-decoration: none;
  font-size: 20px;
}

.toggle-button {
  border: none;
  background-color: transparent;
  color: #097dea;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: underline;
  transition: color 0.3s;
}

.toggle-button:hover {
  color: #097dea;
}