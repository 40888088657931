:root {
    --white: #ffffff;
    --midnight-blue: #002a86;
    --appgyver-cyan: #00a4ff;
    --appgyver-dark-blue: #002a86;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Proximanova webfont', sans-serif;
}

.section {
    background: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 40px;
    display: flex;
    position: relative;
}

.section.no-padding-bottom {
    color: var(--white);
    /* background-color: var(--white); */
    object-fit: fill;
    max-width: none;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 120px;
    overflow: visible;
}

.wrapper {
    z-index: 1;
    background-color: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    display: flex;
    position: relative;
}

.wrapper._1200 {
    max-width: 1200px;
}

.side--feature {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px; /* Optional for spacing */
}

/* Remove stacking in smaller screens */
@media screen and (max-width: 991px) {
    .side--feature {
        flex-direction: row;
    }

    .side-info {
        width: 50%;
        padding-top: 0;
    }

    .div-block-7 {
        width: 50%;
    }
}

.contentSection-box-heading {
    padding-left: 160px;
}

.side-info {
    background-color: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    padding-left: 8%;
    padding-right: 8%;
    display: flex;
}

.div-block-6 {
    /* Add any specific styling for this block if needed */
}

.build-h2-features {
    color: var(--white);
    margin-top: 16px;
    font-family: "72 full", sans-serif;
    font-size: 32px;
}

.build-paragraph-features {
    color: #e0e0e0;
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    margin-top: 0;
    font-family: "72 full", sans-serif;
    font-size: 16px;
    display: block;
    position: static;
}

.div-block-7 {
    justify-content: center;
    align-items: center;
    width: 50%;
    display: flex;
    position: relative;
    right: 0;
}

.background-video-2 {
    width: 600px;
    height: 365px;
    right: 0;
}

.w-background-video {
    color: #fff;
    height: 500px;
    position: relative;
    overflow: hidden;
}

.w-background-video > video {
    object-fit: cover;
    z-index: -100;
    background-position: 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
}

.new-button {
    background-color: var(--white);
    color: var(--appgyver-dark-blue);
    border-radius: 4px;
    padding: 16px 32px;
    font-family: Proximanova webfont, sans-serif;
    font-size: 20px;
}

.new-button.blue {
    background-color: var(--appgyver-cyan);
    padding: 16px 24px;
    display: none;
}

/* Responsive Styles */
@media screen and (max-width: 991px) {
    .section {
        padding: 80px 8px;
        padding-bottom: 80px;
    }

    .section.no-padding-bottom {
        padding-bottom: 0;
    }

    .side-feature {
        flex-flow: column wrap;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
    }

    .side-info {
        order: -1;
        width: 66.66%;
        padding-top: 24px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .div-block-7 {
        width: 100%;
    }

    .background-video-2 {
        order: -1;
    }

    h2 {
        font-size: 26px;
        line-height: 32px;
    }

    p {
        margin-bottom: 24px;
    }
}