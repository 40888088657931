
  /* src/components/DesignPad/styles/ZoomControls.css */
  .io-zoom-controls {
    position: fixed;
    bottom: 120px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .io-control-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .io-control-list button {
    background-color: var(--primary-color, #2796db);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .io-control-list button:hover {
    background-color: var(--primary-hover, #1e7ab8);
  }