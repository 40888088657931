*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  font-size: 20px;
  line-height: 30px;
}
body {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1rem;
  color: #8a94a7;
  background: #1d2026;
  margin: 0;
  overflow-x: hidden;
}
img {
  height: auto;
  max-width: 100%;
  /* vertical-align: middle; */
  display: block;
}
a {
  color: #8a94a7;
  text-decoration: underline;
}
a:hover,
a:active {
  outline: 0;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  clear: both;
  color: #fff;
  font-weight: 600;
}
h1,
.h1 {
  font-size: 38px;
  line-height: 48px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  h1,
  .h1 {
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 0px;
  }
}
h2,
.h2 {
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  h2,
  .h2 {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 0px;
  }
}
h3,
.h3,
blockquote {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  h3,
  .h3,
  blockquote {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0px;
  }
}
h4,
.h4 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
}
@media (min-width: 641px) {
  h4,
  .h4 {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0px;
  }
}
.container,
.container-sm {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 481px) {
  .container,
  .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.container {
  max-width: 1128px;
}
.container-sm {
  max-width: 848px;
}
.container .container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}
.list-reset {
  list-style: none;
  padding: 0;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-primary {
  color: #0270d7;
}
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-8 {
  margin-bottom: 8px;
}
.mt-24 {
  margin-top: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.anime-ready .has-animations .anime-element {
  visibility: visible;
}
.button {
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #242830;
  color: #fff !important;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  padding: 16px 32px;
  height: 48px;
  text-align: center;
  white-space: nowrap;
}
.button:hover {
  background: #262a33;
}
.button:active {
  outline: 0;
}
.button::before {
  border-radius: 2px;
}
.button-primary {
  background: #097dea;
  background: linear-gradient(65deg, #0270d7 0, #0f8afd 100%);
}
.button-primary:hover {
  background: #0982f4;
  background: linear-gradient(65deg, #0275e1 0, #198ffd 100%);
}
.button-block {
  display: flex;
  width: 100%;
}
@media (max-width: 640px) {
  .button-wide-mobile {
    width: 100%;
    max-width: 280px;
  }
}
.site-header {
  padding: 24px 0;
}
.site-header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-links {
  display: inline-flex;
}
.header-links li {
  display: inline-flex;
}
.header-links a:not(.button) {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 600;
  color: #8a94a7;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 16px;
  padding: 8px 24px;
}
@media (min-width: 641px) {
  .site-header {
    position: relative;
  }
  .site-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    background: #242830;
    background: linear-gradient(
      80deg,
      rgba(36, 40, 48, 0.5) 0%,
      rgba(36, 40, 48, 0) 100%
    );
    transform-origin: 0;
    transform: skewY(-12deg);
  }
}
.hero {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 88px;
}
.hero-copy {
  position: relative;
  z-index: 1;
}
.hero-cta {
  margin-bottom: 40px;
}
.hero-figure {
  position: relative;
}
.hero-figure svg {
  width: 100%;
  height: auto;
}
.hero-figure::before,
.hero-figure::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
}
.anime-ready .has-animations .hero-figure::before,
.anime-ready .has-animations .hero-figure::after {
  opacity: 1;
}
.hero-figure::before {
  top: -57.8%;
  left: -1.3%;
  width: 152.84%;
  height: 178.78%;
}
.hero-figure::after {
  top: -35.6%;
  left: 99.6%;
  width: 57.2%;
  height: 87.88%;
}
.hero-figure-box {
  position: absolute;
  top: 0;
  will-change: transform;
}
.hero-figure-box-01,
.hero-figure-box-02,
.hero-figure-box-03,
.hero-figure-box-04,
.hero-figure-box-08,
.hero-figure-box-09 {
  overflow: hidden;
}
.hero-figure-box-01::before,
.hero-figure-box-02::before,
.hero-figure-box-03::before,
.hero-figure-box-04::before,
.hero-figure-box-08::before,
.hero-figure-box-09::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 100% 100%;
}
.hero-figure-box-01 {
  left: 103.2%;
  top: 41.9%;
  width: 28.03%;
  height: 37.37%;
  background: linear-gradient(to left top, #00bffb, rgba(0, 191, 251, 0));
  transform: rotateZ(45deg);
}
.hero-figure-box-01::before {
  background: linear-gradient(to left, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-02 {
  left: 61.3%;
  top: 64.1%;
  width: 37.87%;
  height: 50.5%;
  background: linear-gradient(to left top, #0270d7, rgba(2, 112, 215, 0));
  transform: rotateZ(-45deg);
}
.hero-figure-box-02::before {
  background: linear-gradient(to top, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(-45deg) scale(1.5);
}
.hero-figure-box-03 {
  left: 87.7%;
  top: -56.8%;
  width: 56.81%;
  height: 75.75%;
  background: linear-gradient(to left top, #00bffb, rgba(0, 191, 251, 0));
}
.hero-figure-box-03::before {
  background: linear-gradient(to left, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-04 {
  left: 54.9%;
  top: -8%;
  width: 45.45%;
  height: 60.6%;
  background: linear-gradient(to left top, #0270d7, rgba(2, 112, 215, 0));
  transform: rotateZ(-135deg);
}
.hero-figure-box-04::before {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  transform: rotateZ(-45deg) scale(1.5);
}
.hero-figure-box-05,
.hero-figure-box-06,
.hero-figure-box-07 {
  background-color: #242830;
  box-shadow: -20px 32px 64px rgba(0, 0, 0, 0.25);
}
.hero-figure-box-05 {
  left: 17.4%;
  top: 13.3%;
  width: 64%;
  height: 73.7%;
  transform: perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg);
}
.hero-figure-box-06 {
  left: 65.5%;
  top: 6.3%;
  width: 30.3%;
  height: 40.4%;
  transform: rotateZ(20deg);
}
.hero-figure-box-07 {
  left: 1.9%;
  top: 42.4%;
  width: 12.12%;
  height: 16.16%;
  transform: rotateZ(20deg);
}
.hero-figure-box-08 {
  left: 27.1%;
  top: 81.6%;
  width: 19.51%;
  height: 26.01%;
  background: #0270d7;
  transform: rotateZ(-22deg);
}
.hero-figure-box-08::before {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.48) 100%
  );
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-09 {
  left: 42.6%;
  top: -17.9%;
  width: 6.63%;
  height: 8.83%;
  background: #00bffb;
  transform: rotateZ(-52deg);
}
.hero-figure-box-09::before {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.64) 100%
  );
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-10 {
  left: -3.8%;
  top: 4.3%;
  width: 3.03%;
  height: 4.04%;
  background: rgba(0, 191, 251, 0.32);
  transform: rotateZ(-50deg);
}

.is-boxed {
  background: #242830;
}
.body-wrap {
  background: #1d2026;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  mix-blend-mode: multiply;
  mix-blend-mode: normal;
}
main {
  flex: 1 0 auto;
}
.section-inner {
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;
}
.site-footer {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}
.site-footer a {
  color: #8a94a7;
  text-decoration: none;
}
.site-footer a:hover,
.site-footer a:active {
  text-decoration: underline;
}
.site-footer-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 48px;
  padding-bottom: 48px;
}
.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
  flex: none;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}
.footer-brand,
.footer-links,
.footer-social-links {
  margin-bottom: 24px;
}
.footer-social-links li {
  display: inline-flex;
}
.footer-social-links li + li {
  margin-left: 16px;
}
.footer-social-links li a {
  padding: 8px;
}
.footer-links li + li {
  margin-left: 24px;
}
.has-animations .hero-figure::before,
.has-animations .hero-figure::after {
  opacity: 0;
  transition: opacity 2s ease;
}
/* .has-animations .anime-element {
  visibility: hidden;
} */
@media (max-width: 640px) {
  .hero-cta {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-cta .button {
    display: flex;
  }
  .hero-cta .button + .button {
    margin-top: 16px;
  }
  .hero-figure::after,
  .hero-figure-box-03,
  .hero-figure-box-04,
  .hero-figure-box-09 {
    display: none;
  }
}
@media (min-width: 641px) {
  .hero {
    text-align: left;
    padding-top: 64px;
    padding-bottom: 88px;
  }
  .hero-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hero-copy {
    padding-right: 64px;
    min-width: 552px;
    width: 552px;
  }
  .hero-cta {
    margin: 0;
  }
  .hero-cta .button {
    min-width: 170px;
  }
  .hero-cta .button:first-child {
    margin-right: 16px;
  }
  .hero-figure svg {
    width: auto;
  }
}

@media (min-width: 641px) {
  .section-inner {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}
@media (min-width: 641px) {
  .site-footer {
    margin-top: 20px;
  }
  .site-footer-inner {
    justify-content: space-between;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .footer-brand,
  .footer-links,
  .footer-social-links,
  .footer-copyright {
    flex: 50%;
  }
  .footer-brand,
  .footer-copyright {
    justify-content: flex-start;
  }
  .footer-links,
  .footer-social-links {
    justify-content: flex-end;
  }
  .footer-links {
    order: 1;
    margin-bottom: 0;
  }
}

/* Subscribe button styles */
.subscribe-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: var(--accent-color);
}

/* SVG inside subscribe button */
.subscribe-btn svg {
  height: 60px;
  width: 60px;
}

/* Floating YouTube Button Styling */
.youtube-button {
  position: fixed;
  bottom: 20px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
  background-color: #ff0000; /* YouTube red color */
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 2;
}

/* Icon Styling */
.youtube-button i {
  font-size: 24px;
}

/* Hover Effect */
.youtube-button:hover {
  background-color: #e60000; /* Darker shade of red */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px); /* Slight upward movement */
}
