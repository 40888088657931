
/* Logo.css */
.bottom-con {
  z-index: -1;
}

.top-con {
  filter: url('#fancy-goo');
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

.top-con{
  width: 120px;  /* Reduced from 300px */
  height: 120px; /* Reduced from 300px */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: 1px solid #e7e7e7; */
}

.element {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 52px; /* Reduced from 130px */
  background: white;
}

.blob-container {
  width: 100%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Blob container rotations remain the same */
.blob-container:nth-child(1) { transform: translate(-50%, -50%) rotate(-95deg); }
.blob-container:nth-child(2) { transform: translate(-50%, -50%) rotate(-85deg); }
.blob-container:nth-child(3) { transform: translate(-50%, -50%) rotate(-88deg); }
.blob-container:nth-child(4) { transform: translate(-50%, -50%) rotate(-92deg); }
.blob-container:nth-child(5) { transform: translate(-50%, -50%) rotate(-120deg); }
.blob-container:nth-child(6) { transform: translate(-50%, -50%) rotate(-60deg); }

/* Animation delays remain the same */
.blob-container:nth-child(1) .blob { animation-delay: 0ms; }
.blob-container:nth-child(2) .blob { animation-delay: 350ms; }
.blob-container:nth-child(3) .blob { animation-delay: 800ms; }
.blob-container:nth-child(4) .blob { animation-delay: 1200ms; }
.blob-container:nth-child(5) .blob { animation-delay: 850ms; }
.blob-container:nth-child(6) .blob { animation-delay: 500ms; }

.blob {
  left: 50%;
  top: 50%;
  width: 26px;   /* Reduced from 65px */
  height: 26px;  /* Reduced from 65px */
  background: white;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 38px;  /* Reduced from 95px */
  border-radius: 99em;
  animation-name: move;
  animation-iteration-count: infinite;
  animation-duration: 1600ms;
  animation-timing-function: ease-in-out;
  background: linear-gradient(to top right, #f32c3c, #FF802B);
}

@keyframes move {
  0% {
    left: 50%;
    top: 50%;
    width: 30px;   /* Reduced from 75px */
    height: 26px;  /* Reduced from 65px */
  }

  30% {
    left: 50%;
    top: 50%;
    width: 26px;   /* Reduced from 65px */
    height: 34px;  /* Reduced from 85px */
  }

  70% {
    left: 90%;
    top: 50%;
    width: 10px;   /* Reduced from 25px */
    height: 10px;  /* Reduced from 25px */
  }

  90% {
    left: 55%;
    top: 50%;
    width: 1px;
    height: 1px;
  }

  100% {
    left: 50%;
    top: 50%;
    width: 26px;   /* Reduced from 65px */
    height: 30px;  /* Reduced from 75px */
  }
}

