/* Base styling */
@import "~@fortawesome/fontawesome-free/css/all.min.css";

/* body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  margin: 0;
} */

/* Container styling */
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  max-width: 250px; /* Reduced width for more compact layout */
}

/* PDF Card styling */
.pdf-card {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1), -3px -3px 10px rgba(255, 255, 255, 0.9);
  transition: transform 0.2s;
  width: 100%; /* Ensures responsive scaling */
  max-width: 250px; /* Controls card width */
}

.pdf-card:hover {
  transform: translateY(-3px);
}

/* PDF Icon Styling */
.pdf-icon {
  font-size: 1.5em; /* Smaller icon size */
  color: #e74c3c;
  margin-right: 10px;
}

/* File Name Styling */
.file-name {
  font-size: 0.9em; /* Smaller font size */
  font-weight: bold;
  color: #333;
  flex: 1;
  margin-right: 10px;
  white-space: nowrap; /* Prevents wrapping of file name */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis for long file names */
}

/* Download Icon Styling */
.download-btn {
  font-size: 1.2em; /* Icon size */
  color: #3498db;
  text-decoration: none;
  transition: color 0.2s;
}

.download-btn:hover {
  color: #2980b9;
}
