
  /* src/components/DesignPad/styles/Grid.css */
  .design-pad__grid {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  
  .grid-container {
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Full viewport height */
    /* margin-top: 1rem; */
  }
  
  .content-layout {
    margin-top: 3rem;
    position: relative;
    z-index: 1; /* Ensure content is above the SVG */
    width: 84.5%; /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content left with slight margin */
    justify-content: center; /* Vertically center the content */
  }
  
  .main-content {
    width: 100%;
    margin: 0 auto; /* Center content horizontally */
    background: transparent; /* Transparent background */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Optional: Add shadow for better visibility */
    padding: 20px;
  }
  